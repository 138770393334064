.tab-list-item {
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
    color: #3310ff;
    font-size: 14px;
    display: flex;
    flex: 1;
    justify-content: center;
}
  
.tab-list-active {
    border-radius: 3rem;
    background-color: #FFF;
    color: #6A737D;
}
