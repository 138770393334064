


.dapp-list-category-container{
    display: flex;
    flex: 1;
    min-width: 100%;
}

.dapp-list-category{
    display: flex;
    flex: 1;
    height: 64px;
    background: #FFFFFF;
    /*box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);*/
    border-radius: 8px;
    text-align: center;
    align-items: center;
    min-width: 150px;
    margin: 5px 10px;
    padding-left: 15px;
    padding-right: 8px;
    border: 1px solid #e2e4e5;
}

.dapp-list-category:nth-child(even) {
    margin-right: 0px;
}

.dapp-list-category-icon {
    font-size: 32px;
    line-height: 32px;
    width: 32px;
    height: 32px;
    background-color: #037dd6;
    border-radius: 3px;
}

.dapp-list-category-name {
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #24292E;
    padding: 8px;
}

